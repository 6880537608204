import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Karriereseiten API (Custom API)",
  "description": "Eine API-Integration ist der komplizierteste Weg, aber er bietet Ihnen unbegrenzte Gestaltungsmöglichkeiten.",
  "author": "Jochen Ritscher",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false,
  "tags": ["powerapps"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Eine API-Integration ist der komplizierteste Weg, aber er bietet Ihnen unbegrenzte Gestaltungsmöglichkeiten.
Sie basiert auf REST.
Funktionen:`}</p>
    <ul>
      <li parentName="ul">{`Job Export -> REST API / XML Feed zum Lesen von Stellenanzeigen`}</li>
      <li parentName="ul">{`Kandidatenimport ->REST API zum Erstellen von Bewerbern, Bewerbungen, Dateianhängen undweiteren Stammdaten`}</li>
      <li parentName="ul">{`Status-Synchronisierung`}</li>
      <li parentName="ul">{`flexibel erweiterbar`}</li>
      <li parentName="ul">{`Optional: E-Mail-Benachrichtigung, Posting zu Microsoft Teams, Slack, Stellenanzeigenposting zu 100+ Plattformen und Suchmaschinen, Onboarding, etc.`}</li>
      <li parentName="ul">{`DSGVO-konforme Löschung & Self-Service-Feature`}</li>
    </ul>
    <p>{`Hinweis: APIs benötigen fundierte Programmierkenntnisse! Als API-Benutzer verwalten Sie die Businesslogik Ihrer Karriereseite komplett selbst.`}</p>
    <p>{`Für eine technische Beschreibung der Karriereseiten API kontaktieren Sie bitte `}<a parentName="p" {...{
        "href": "mailto:support@inriva.com"
      }}>{`support@inriva.com`}</a>{` `}</p>
    <img src="/images/faqs/custom+api.png" alt="Karriereseiten-API" style={{
      "width": "100%"
    }} />
    <p>{`Hinweis: Wenn Sie die Karriereseiten API nutzen um Jobs auf Ihre eigene Website zu veröffentlichen, wird eine deaktivierte Standard-Karriereportal-Plattform für Sie veröffentlicht (ihrkarriereportal.inriva.com). Bitte kontaktieren Sie `}<a parentName="p" {...{
        "href": "mailto:support@inriva.com"
      }}>{`support@inriva.com`}</a>{` wenn Sie diese aktivieren oder ändern möchten. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      